import React, { useState, useEffect } from 'react';

import { SettingsHeader } from './components/settingheader';
import { MenuList } from './components/menu-list';
import { ApiCalls } from '../common/apiCalls';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export function SettingsIndex({ onClose, showToast }) {
    const { cardDetails, cardDetailsApiCall } = ApiCalls();
    const [isLoading, setLoading] = useState(true);

    const [isCardLocked, setIsCardLocked] = useState(false);
    const [isCardStatusChanged, setIsCardStatusChanged] = useState(false);

    useEffect(() => {
        if (cardDetails?.card?.status === 'ALLOCATED') {
            setIsCardLocked(false);
        } else {
            setIsCardLocked(true);
        }
        if (cardDetails !== undefined) {
            setTimeout(() => {
                setLoading(false);
            }, 100);
        }
        // console.log('cardDetails: ', cardDetails);
    }, [cardDetails]);


    const handleLockStatusChange = (newStatus) => {
        setIsCardStatusChanged(true);
    };

    // Safely call onClose to prevent unintended executions
    const handleClose = () => {
        onClose(isCardStatusChanged);
    };

    return (
        <>
            <div className="sidebar-wrapper fullpage">
                <div className="sidebar-container">
                    <SettingsHeader onClose={handleClose} />
                    <div className="sidebar-body-wrapper">
                        <div className="secondary-header-wrapper">
                            <div> Manage your card settings here </div>
                        </div>
                        <MenuList
                            isCardLocked={isCardLocked}
                            cardDetails={cardDetails}
                            cardDetailsApiCall={cardDetailsApiCall}
                            onLockStatusChange={handleLockStatusChange} showToast={showToast}
                        />
                    </div>
                </div>
                <div className="sidebar-overlay" onClick={handleClose}></div>
            </div>

            {isLoading && (
                <div className="custom-loader">
                    <div className="custom-loader-icon">
                        <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7' }} />
                    </div>
                </div>
            )}
        </>
    );
}

