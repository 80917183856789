import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

export function ToastNotification({ className, show, message, icon, onClose, delay = 5000 }) {
  return (
    <ToastContainer>
      <Toast className={className} onClose={onClose} show={show} delay={delay} autohide>
        {/* <Toast.Header>
          <strong className="me-auto">Notification {message}</strong>
          <small>Just now</small>
        </Toast.Header> */}
        <Toast.Body>
          <div className="toast-body-wrapper">
            {icon && (
              <div className="toast-icon">
                <i className={icon}></i>
              </div>
            )}
            <div className="toast-text">{message}</div>
            <button className="btn close-btn" onClick={onClose}><i className="feather-x"></i></button>
          </div>
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
};