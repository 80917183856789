import React, { useState, useEffect } from 'react';
import { ChangeBillingHeader } from '../change-billing/components/change-billing-header';
import { ChangeBillingList } from './components/change-billing-list';
import { fetchDueDetails, fetchBillingDates } from '../../common/cms-sdk/index';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export function ChangeBillingIndex({ showToast }) {
    const [errorMsg, setErrorMsg] = useState("");
    const [dueAmount, setDueAmount] = useState(0);
    const [billingDates, setBillingDates] = useState([]);
    const [currentStatementDate, setCurrentStatementDate] = useState(null);
    let [loading, setLoading] = useState(true);

    async function fetchDueAmount() {
        try {
            const dueData = await fetchDueDetails();
            setDueAmount(dueData?.totalDue);
            setCurrentStatementDate(dueData?.currentStatementDate);
        } catch (error) {
            setErrorMsg("Failed to fetch due amount");
            console.log("Error fetching due amount:", error);
        }
    }

    async function fetchDates() {
        try {
            const billingDatesResponse = await fetchBillingDates();
            if (billingDatesResponse && Array.isArray(billingDatesResponse)) {
                setBillingDates(billingDatesResponse);
            } else {
                console.log("Error: Unexpected API response format or missing data");
                setBillingDates([]);
            }
        } catch (error) {
            console.log("Error fetching billing dates:", error);
        }
    }

    useEffect(() => {
        async function fetchData() {
            try {
                await Promise.all([fetchDueAmount(), fetchDates()]);
            } catch (error) {
            } finally {
                setLoading(false); 
            }
        }
        fetchData();
    }, []);

    return (
        <>
            <ChangeBillingHeader />
            {
            loading ? (<div className="loader" >
                <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '40%' }} />
            </div>) : (<>
            <ChangeBillingList dueAmount={dueAmount} billingDates={billingDates} currentStatementDate={currentStatementDate} showToast={showToast} /></>)
        }
        </>
    )
}
