import axios from 'axios';
import { BaseURL, Endpoints } from './api';

export default class CmsClient {

    constructor() {
        this.axiosInstance = null;
    }

    //Initialize the class by creating a config and axios instance with error handling which would be common for all the API calls
    initialize() {
        const config = {
            baseURL: BaseURL,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        this.axiosInstance = axios.create(config);
        // Add a request interceptor to modify headers conditionally
        this.axiosInstance.interceptors.request.use(
            (config) => {
                const authToken = localStorage.getItem("authToken"); // Replace with your token retrieval logic
                if (authToken) {
                    config.headers["Authorization"] = `Bearer ${authToken}`;
                }

                if (config.url.includes(Endpoints.LOCK_CARD) || config.url.includes(Endpoints.UNLOCK_CARD) || config.url.includes(Endpoints.CLOSE_CARD)) {
                    const actionToken = localStorage.getItem("actionToken"); // Replace with your token retrieval logic
                    if (actionToken) {
                        config.headers["Action-Token"] = `Bearer ${actionToken}`;
                    }
                }

                // Add other conditional headers if needed
                if (config.customHeaders) {
                    Object.assign(config.headers, config.customHeaders);
                }

                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        this.axiosInstance.interceptors.response.use(
            (response) => response,
            (error) => {
                console.log('API error', error);
                if (error?.response) {
                    // The request was made and the server responded with a status code that falls out of the range of 2xx
                    if (error?.response?.status === 401) {
                        console.log('API response', error?.response?.config);
                        if (error?.response?.config?.url && (error?.response?.config?.url.includes(Endpoints.TOKEN_GENERATE))) {
                            console.log('Unauthorized Request. actionToken');
                        } else {
                            console.log('Unauthorized Request. Redirecting to login...');
                            // When the token is expired we are directly redirecting the user sign in screen.
                            if (window.location.pathname !== '/') {
                                // localStorage.removeItem('authToken');
                                // localStorage.removeItem('actionToken');
                                if (window.location.hostname === 'localhost') {
                                    // window.location.href = '/';
                                } else {
                                    window.location.href = process.env.REACT_APP_ONBOARDING_HOST_URL+'cms?session_expired=true';
                                }
                            }
                        }

                    } else if (error?.response?.status === 404) {
                        console.log('Resource Not Found');
                    } else if (error?.response?.status >= 400 && error?.response?.status < 500) {
                        console.log('Client Error:', error?.response.status);
                    } else if (error?.response?.status >= 500 && error?.response?.status < 600) {
                        console.log('Server Error:', error?.response?.status);
                    } else {
                        console.log('Response error:', error?.message);
                    }
                } else if (error?.request) {
                    // The request was made but no response was received
                    console.log('No response received:', error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Request error:', error?.message);
                }
                return Promise.reject(error);
                // return error;
            });
    }

    //Used to authenticate user with the input details(Eg: Using PhoneNo) so as to fetch back a token for all other calls
    async authenticate(data) {
        try {
            // console.log(`Axios Instance: ${JSON.stringify(this.axiosInstance)}`);
            const authenticationResponse = await this.axiosInstance.post(Endpoints.Authentication, data);
            // console.log(`Authentication Response: ${JSON.stringify(authenticationResponse)}`)
            const token = authenticationResponse?.data?.data?.token;
            if (token) {
                localStorage.setItem('authToken', token);
                console.log(`Authentication successful with token generation !!`)
                return authenticationResponse?.data?.data;
            }
            return false;
        } catch (error) {
            console.log('Authentication of User failed due to the reason:', error?.message);
            return error;
        }
    }

    //This endpoint is responsible for forgot mpin
    async tokenGenerateCall(pin, action) {
        try {
            // console.log(`Axios Instance: ${JSON.stringify(this.axiosInstance)}`);
            const authenticationResponse = await this.axiosInstance.post(Endpoints.TOKEN_GENERATE, { pin, action });
            const token = authenticationResponse?.data?.data?.token;
            if (token) {
                localStorage.setItem('actionToken', token);
                const actionToken = localStorage.getItem('actionToken');
                return authenticationResponse?.data?.data;
            }
            return false;
        } catch (error) {
            console.log('Authentication of User failed due to the reason:', error?.message);
            return error;
        }
    }

    //GET call functionality common for all API calls
    async doGetCall(endpoint) {
        try {
            const response = await this.axiosInstance.get(endpoint);
            return response?.data;
        } catch (error) {
            console.log('Error making GET request:', error?.message);
            return error;
        }
    }

    //POST call functionality common for all API calls
    async doPostCall(endpoint, body) {
        try {
            const response = await this.axiosInstance.post(endpoint, body);
            return response?.data;
        } catch (error) {
            console.log('Error making POST request:', error?.message);
            return error;
        }
    }

    //This endpoint is responsible for fetching balance following a secure authentication flow with token validation added.
    async getBalance() {
        return await this.doGetCall(Endpoints.GetBalance)
    }

    //This endpoint is responsible for fetching all card details (with respective status) data following a secure authentication flow with token validation added.
    async getCardList() {
        return await this.doGetCall(Endpoints.GetCardList);
    }

    //This endpoint is responsible for fetching active card details data following a secure authentication flow with token validation added.
    async getCardDetails() {
        return await this.doGetCall(Endpoints.GetCardDetails);
    }

    //This endpoint is responsible for fetching Card Preference data following a secure authentication flow with token validation added.
    async getPreference() {
        return await this.doGetCall(Endpoints.GetPreference);
    }

    //This endpoint is responsible for setting Card Preference data following a secure authentication flow with token validation added.
    async setPreference(body) {
        return await this.doPostCall(Endpoints.SetPreference, body);
    }

    //This endpoint is responsible for setting a Limit on Card usage following a secure authentication flow with token validation added.
    async setCardLimit(body) {
        return await this.doPostCall(Endpoints.SetLimit, body);
    }

    //This endpoint is responsible for fetching the Limit on Card usage following a secure authentication flow with token validation added.
    async getCardLimit() {
        return await this.doGetCall(Endpoints.GetLimit);
    }

    async UpgradeLimit(body) {
        return await this.doPostCall(Endpoints.UpgradeLimit, body);
    }

    //This endpoint is responsible to fetch the CVV of the registered card and is applicable mainly for virtual cards with a secure authentication flow with token validation added.
    async getCvv(body) {
        return await this.doPostCall(Endpoints.GetCvv, body);
    }

    //This endpoint is responsible to fetch Statement Summary for a customer/card for a month in Json format.
    async getStatement(body) {
        return await this.doPostCall(Endpoints.GetStatement, body);
    }

    //This endpoint responsible for updating the statement date while changing the billing cycle of the user card when there's no due amount to be paid
    async updateStatement(body) {
        return await this.doPostCall(Endpoints.UpdateStatement, body);
    }

    //This endpoint responsible for fetching the unbilled transactions of the user's card within the timeline specified
    async getUnbilledTransactions() {
        return await this.doGetCall(Endpoints.GetUnbilledTransactions);
    }

    //This endpoint responsible for fetching the transactions status of the user's card within the timeline specified
    async getTransactions(body) {
        return await this.doPostCall(Endpoints.GetTransactions, body);
    }

    //This endpoint is responsible for requesting physical card approval for the user to make use of the card with a secure authentication added.
    async requestPhysicalCard(body) {
        return await this.doPostCall(Endpoints.RequestPhysicalCard, body);
    }

    //This endpoint is responsible for fetching due amount details
    async getDue() {
        return await this.doGetCall(Endpoints.GetDue);
    }

    //This endpoint is responsible for setting a new pin on Card
    async setCardPin(body) {
        return await this.doPostCall(Endpoints.SetPin, body);
    }

    //This endpoint is responsible for fetching billing dates
    async getBillingDates() {
        return await this.doGetCall(Endpoints.GetBillingDates);
    }

    //This endpoint is responsible for locking the Card
    async lockCard(kitNo, reason) {
        return await this.doPostCall(Endpoints.LOCK_CARD, { kitNo, reason });
    }

    //This endpoint is responsible for unlocking the Card
    async unlockCard(kitNo, reason) {
        return await this.doPostCall(Endpoints.UNLOCK_CARD, { kitNo, reason });
    }

    //This endpoint is responsible for unlocking the Card
    async blockCard(kitNo, reason) {
        return await this.doPostCall(Endpoints.BLOCK_CARD, { kitNo, reason });
    }

    //This endpoint is responsible for fetching all card details filtered with respective status that is given in the request payload with post call.
    async getCardListPost(body) {
        return await this.doPostCall(Endpoints.GetCardList, body);
    }

    //This endpoint is responsible for Reset of the Card's Mpin
    async resetCardMpin(body) {
        return await this.doPostCall(Endpoints.ResetMpin, body);
    }

    //This endpoint is responsible for Closing the Card
    async closeCard(kitNo, reason) {
        return await this.doPostCall(Endpoints.CLOSE_CARD, { kitNo, reason });
    }

    //This endpoint is responsible for fetching the Emi Eligible Transactions
    async getEmiEligibleTransactions() {
        return await this.doGetCall(Endpoints.EmiEligibleTransactions);
    }

    // //This endpoint is responsible for fetching the EMI Eligible List
    async getEmiEligibleList() {
        return await this.doGetCall(Endpoints.EmiEligibleList);
    }

    //This endpoint is responsible for fetching the Emi Preview Details
    async getEmiPreview(body) {
        return await this.doPostCall(Endpoints.EmiPreview, body);
    }

    //This endpoint is responsible for Replacing the Card with status change
    async replaceCard(kitNo, reason) {
        return await this.doPostCall(Endpoints.REPLACE_CARD, { kitNo, reason });

    }

    //This endpoint is responsible for fetching the Previous Statements Details
    async GetPreviousStatements(monthYear) {
        return await this.doPostCall(Endpoints.PreviousStatements, { monthYear: monthYear });
    }

    //This endpoint is responsible for send otp
    async OtpSend(mobileNumber, template) {
        return await this.doPostCall(Endpoints.OTP_SEND, { mobileNumber, template });
    }

    //This endpoint is responsible for forgot mpin
    async ForgotMpin(body) {
        return await this.doPostCall(Endpoints.USER_FORGOT_MPIN, body);
    }


}