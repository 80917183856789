import { useState, useEffect } from 'react';
import { fetchCardBalance, fetchCardDetails, fetchCvvDetails, fetchLimitDetails, fetchUnbilledTransactions, updateNewPin, cardLock, unlockCard, cardBlock, fetchCardListPost, fetchTransactions, fetchPreviousStatements } from '../../common/cms-sdk/index';

export function ApiCalls() {
    let [cardBalance, setCardBalance] = useState(0);
    let [cardDetails, setCardDetails] = useState();
    let [unbilledTransactions, setUnbilledTransactions] = useState();
    let [transactionsDetails, setTransactions] = useState();
    let [limitDetails, setLimitDetails] = useState();
    let [cvvDetails, setCvvDetails] = useState();
    let [cardListDetails, setCardListDetails] = useState();
    let [loading, setLoading] = useState(false);
    let [buttonClick, setButtonClick] = useState(false);
    let [isCardLocked, setIsCardLocked] = useState(false);
    let [previousStatements, setPreviousStatements] = useState([]);

    async function CardApicall() {
        setLoading(true);
        // const BalanceDetails = await fetchCardBalance();
        // if (BalanceDetails) {
        //     console.log('BalanceDetails: ', BalanceDetails);
        // }
        // setCardBalance(BalanceDetails?.balanceAmount);
        const detailsOfCard = await fetchCardDetails();
        setCardDetails(detailsOfCard);
        // const limit = await fetchLimitDetails();
        // setLimitDetails(limit);

        // const cvv = await fetchCvvDetails({
        //     "kitNo": "4000000782",
        //     "expiryDate": "2602",
        //     "dob": "01012000"
        // });
        // setCvvDetails(cvv);
        // const cardListDetails = await fetchCardListPost();
        // setCardListDetails(cardListDetails);
        // const lockedCardList = cardListDetails.find(card => card.status === "LOCKED");
        // setIsCardLocked(lockedCardList.length > 0);
        // const details = await fetchUnbilledTransactions();
        // setUnbilledTransactions(details);
        // const transactionDetails = await fetchTransactions({
        //     "fromDate": "2000-11-11",
        //     "toDate": "2024-11-11"
        // });
        // setTransactions(transactionDetails);
        setLoading(false);
    }

    useEffect(() => {
        CardApicall();
        setButtonClick(false);
    }, [buttonClick])

    const setNewPinApiCall = async (pin) => {
        const resultData = await updateNewPin({
            "kitNo": cardDetails?.kitNo,
            "expiryDate": (cardDetails?.cardExpiry).substring(2, 4) + (cardDetails?.cardExpiry).substring(0, 2),
            "dob": cardDetails?.dob,
            "pin": pin
        });
        return resultData;
    }

    const cardDetailsApiCall = async () => {
        try {
            const detailsOfCard = await fetchCardDetails();
            setCardDetails(detailsOfCard);
        } catch (error) {
            console.log('Error unlocking card:', error);
        }
    };

    const blockCardApiCall = async () => {
        try {
            let kitNo;
            if (!isCardLocked) {
                kitNo = cardDetails?.kitNo;
            } else {
                const lockedCard = cardListDetails.find(card => card.status === "LOCKED");
                if (lockedCard) {
                    kitNo = lockedCard.kitNo;
                } else {
                    console.log('No locked cards found');
                    return;
                }
            }
            const reqBody = { kitNo, reason: "Block Card" };
            const blockCardDetails = await cardBlock(kitNo, "Block Card");
            if (blockCardDetails === 'success') {
                console.log("Card blocked successfully:", blockCardDetails);
            } else {
                console.log("Card blocking unsuccessful:", blockCardDetails);
            }
        } catch (error) {
            console.log('Error blocking card:', error);
        }
    };

    // useEffect(() => {
    //     CardApicall();
    //     setButtonClick(false);
    // }, [buttonClick])

    useEffect(() => {
        // fetchPreviousStatementsData();
    }, []);

    const fetchPreviousStatementsData = async () => {
        try {
            const previousStatementsData = await fetchPreviousStatements('122024');
            setPreviousStatements(previousStatementsData);
        } catch (error) {
            console.log('Error fetching previous statements:', error);
        }
    };

    return { setButtonClick, cardBalance, cardDetails, unbilledTransactions, transactionsDetails, cvvDetails, loading, setNewPinApiCall, blockCardApiCall, previousStatements, cardDetailsApiCall };
}
