import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { fetchUpdateStatement } from '../../../common/cms-sdk';

export function ChangeBillingList(props) {
    const { currentStatementDate, showToast } = props;
    const [isLoading, setLoading] = useState(false);
    const [buttonClick, setButtonClick] = useState(false);
    const [nextBillingDueDate, setNextBillingDueDate] = useState("");
    const [formattedCurrentDate, setFormattedCurrentDate] = useState("");
    const [selectedBillingDate, setSelectedBillingDate] = useState();

    const navigate = useNavigate();

    useEffect(() => {
        if (currentStatementDate) {
            const currentDate = new Date(currentStatementDate);
            const nextDueDate = new Date(currentDate);
            nextDueDate.setDate(nextDueDate.getDate() + 18);

            if (nextDueDate.getMonth() !== currentDate.getMonth()) {
                nextDueDate.setMonth(nextDueDate.getMonth() + 1);
            }

            const formattedCurrent = getDateWithOrdinal(currentDate.getDate());
            const formattedNextDueDate = getDateWithOrdinal(nextDueDate.getDate());

            setFormattedCurrentDate(formattedCurrent);
            setNextBillingDueDate(formattedNextDueDate);
        }
    }, [currentStatementDate]);

    async function handleConfirmUpdateBillingDate() {
        setLoading(true);
        try {
            const updateStatementResponse = await fetchUpdateStatement({ "stmtDate": selectedBillingDate });
            if (updateStatementResponse) {
                showToast('Billing statement date is successfully updated!', 'success');
                setLoading(false);
                navigate(-1);
            }
        } catch (error) {
            console.log("Error updating Statement:", error);
            setLoading(false);
        }
    }

    const handleRadioClick = (billingDate) => {
        setButtonClick(true);
        setSelectedBillingDate(billingDate);
    }

    function getDateWithOrdinal(date) {
        const n = parseInt(date);
        if (n >= 11 && n <= 13) {
            return n + "th";
        }
        switch (n % 10) {
            case 1:
                return n + "st";
            case 2:
                return n + "nd";
            case 3:
                return n + "rd";
            default:
                return n + "th";
        }
    }

    return (
        <>
            <div className="body-wrapper has-padding has-fixed-footer has-fixed-footer-text">
                <div className="body-container">
                    <div className="form-group">
                        <label className="form-label fw-600">Current billing cycle:</label>
                        {currentStatementDate ? (
                            <p>Billing cycle starts on {formattedCurrentDate} of every month. {formattedCurrentDate > nextBillingDueDate ? 'Pay by ' + nextBillingDueDate + ' of the next month.' : 'Pay by ' + nextBillingDueDate + ' of the same month.'}</p>
                        ) : (
                            <p>Billing cycle is not chosen. Please choose a billing cycle</p>
                        )}
                    </div>
                    <div className="form-group">
                        <label className="form-label fw-600">Upcoming billing cycles you can choose from:</label>
                        <div className="pt-1">
                            {props.billingDates.map((dateObj, index) => (
                                <div key={index} className="custom-radio-item-wrapper">
                                    <label className="form-check-radio">
                                        <input
                                            type="radio"
                                            name="billingCycle"
                                            id={`billingCycle${index + 1}`}
                                            className="form-check-input-radio"
                                            onClick={() => handleRadioClick(dateObj?.billingDate)}
                                        // disabled={props.dueAmount > 0 || !(dateObj?.eligible)}
                                        />
                                        <span
                                            htmlFor={`billingCycle${index + 1}`}
                                            className="form-check-label-radio"
                                        >
                                            Billing cycle starts on {getDateWithOrdinal(dateObj?.billingDate)} of every month. {dateObj?.billingDate > dateObj?.paymentDate ? 'Pay by ' + getDateWithOrdinal(dateObj?.paymentDate) + ' of the next month.' : 'Pay by ' + getDateWithOrdinal(dateObj?.paymentDate) + ' of the same month.'}
                                        </span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>


                <div className='page-footer-wrapper'>
                    {props.dueAmount > 0 && (
                        <div className="alert-item-wrapper danger-alert mb-2">
                            <div className="alert-item-container">
                                {/* <div className="alert-icon"><i className="feather-alert-circle"></i></div> */}
                                <div className="alert-text text-center"><small>Please clear your total outstanding amount to change your billing cycle.</small></div>
                            </div>
                        </div>
                    )}
                    <div className='page-footer-container'>
                        <button className="btn btn-primary btn-lg btn-circular page-footer-btn" onClick={handleConfirmUpdateBillingDate} disabled={!(buttonClick) || props.dueAmount > 0}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>

            {isLoading && (
                <div className="custom-loader">
                    <div className="custom-loader-icon">
                        <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7' }} />
                    </div>
                </div>
            )}
        </>
    )
}
