import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { cardClose } from '../../../common/cms-sdk/index';
import MpinPopup from "../../../common/mpin-popup/mpin-popup.js";
import { Endpoints } from '../../../common/cms-sdk/api';

export function CardClosure({ showToast }) {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(true);
    const [selectedOption, setSelectedOption] = useState('');
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const reasonList = [
        'I don\'t like the coin reward system',
        'I don\'t find this card to be secure and safe',
        'I have found a better card',
        'Card charges are too high',
        'Card does not offer on other apps',
        'Option is not listed here'
    ];

    useEffect(() => {
        setIsOptionSelected(selectedOption !== '');
    }, [selectedOption]);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const toggleDropdown = () => {
        // setIsDropdownOpen(!isDropdownOpen);
    };

    const handleContactSupportClick = async () => {
        setShowModal(true);
        // try {
        //     // Call cardClose function with selected option as the body
        //     const response = await cardClose("", selectedOption);
        //     // Check if the request was successful
        //     if (response === true) {
        //         setCountdownMessage("Request submitted successfully");
        //         setTimeout(() => {
        //             navigate(-1);
        //         }, 3000);
        //     }
        //     // else {
        //     //     console.log("Request failed:", response);
        //     //     setCountdownMessage("Failed to submit request. Please try again.");
        //     // }
        // } catch (error) {
        //     console.log("Error submitting request:", error);
        //     setCountdownMessage("Failed to submit request. Please try again.");
        // }
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleLockCard = async () => {
        setShowModal(false);
        navigate(-1, { state: { isStatusChaned: true }});
    };

    return (
        <>
            <div className="body-wrapper has-padding has-fixed-footer">
                <div className="body-container">
                    <div className="page-title-wrapper">
                        <div className="page-title">Submit close card request?</div>
                        <div className="page-description">Dear customer, your satisfaction is important to us. If you still choose to close your card, please select a reason and our customer support team will assist you </div>
                    </div>

                    <div className="">
                        <label className="form-label">Tell us what is not working for you:</label>
                        {/* <button className="btn toggle-btn" type="button" onClick={toggleDropdown}>
                            <span className='btn-toggle-text'>Tell us what is not working for you</span>
                            <span className='btn-toggle-icon'>
                                {isDropdownOpen ? (
                                    <i className="feather-chevron-up"></i>
                                ) : (
                                    <i className="feather-chevron-down"></i>
                                )}
                            </span>
                        </button> */}
                        {isDropdownOpen && (
                            <>
                                {reasonList.map((resonItem, index) => (
                                    <div className="custom-radio-item-wrapper" key={'resonItem_'+index}>
                                        <label className="form-check-radio" htmlFor={'reson_'+index}>
                                            <input type="radio" name="closureReason" id={'reson_'+index} className="form-check-input-radio"
                                                onClick={() => handleOptionChange(resonItem)} />
                                            <span>{resonItem}</span>
                                        </label>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
                <div className='page-footer-wrapper'>
                    <div className='page-footer-container'>
                        {/* <button className="btn btn-primary btn-lg btn-circular page-footer-btn" onClick={handleContactSupportClick} disabled={!isOptionSelected}>
                            Take me to Unity Support
                        </button> */}
                        <button className="btn btn-primary btn-lg btn-circular page-footer-btn" onClick={handleContactSupportClick} disabled={!isOptionSelected}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>


            {showModal && (
                <MpinPopup modalTitle={'Close your card'} submitText={'Yes, close my card'} onSubmit={handleLockCard} closeModal={closeModal}
                    apiDetails={Endpoints.CLOSE_CARD} showToast={showToast} actionReason={selectedOption}
                    modalDescription={''}></MpinPopup>
            )}
        </>
    );
}
