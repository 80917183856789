import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CardIndex } from './pages/home/cardIndex';
import { MpinVerifyIndex } from './pages/mpin-Verify/mpin-verify-index';
import { ForgotMpinIndex } from './pages/forgot-mpin/forgot-mpin-index';
import { ResetMpinIndex } from './pages/reset-mpin/reset-mpin-index';
import { ChangeBillingIndex } from './pages/change-billing/ChangeBillingIndex.js';
import { PinResetIndex } from './pages/atm-Pin-Setup/atm-pin-setup-index.js';
import { LimitControlsIndex } from './pages/limit-Controls/limit-controls-index.js';
import { BlockCardIndex } from './pages/blockCard/blockCardIndex.js';
import { StatementsIndex } from './pages/latest-statements/latest-statementIndex.js';
import ComingSoon from './pages/common/comingSoon.js';
import { CardClosureIndex } from './pages/card-Closure/card-closure-index.js';
import { StatementDetailsIndex } from './pages/statement-details/statement-details.js';
import { UnbilledTransactionsIndex } from './pages/transactions-unbilled/transactions-unbilled-index.js';
import { EmiTransactionIndex } from './pages/emiTransaction/emiTransactionIndex.js';
import { EasyEmi } from './pages/common/easyEmi.js';
import { AboutCardIndex } from './pages/about-Card/about-card-index.js';
import { EmiTrackingIndex } from './pages/emi-Tracking/emi-tracking-index.js';
import { SettingsStateProvider } from './pages/common/settingState.js';
import { ToastNotification } from './common/toast-notification/toast-notification';
import '../src/assets/styles/styles.scss';

const App = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [orientation, setOrientation] = useState(window.orientation || 0);
  const [toasts, setToasts] = useState([]);

  const generateUUID = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }
  // Function to show toast
  const showToast = (message, toastType) => {
    if (message) {
      let options = { id: generateUUID(), className: '', message, icon: '', delay: 5000 };
      if (toastType) {
        switch (toastType) {
          case 'success':
            options.delay = 3000;
            options.icon = 'fa-solid fa-circle-check';
            options.className = 'success-toast';
            break;
          case 'danger':
            options.icon = 'fa-solid fa-circle-xmark';
            options.className = 'danger-toast';
            break;
          case 'warning':
            options.icon = 'fa-solid fa-circle-exclamation';
            options.className = 'warning-toast';
            break;
          case 'info':
            options.icon = 'fa-solid fa-circle-question';
            options.className = 'info-toast';
            break;
          default:
            console.log('Unknown action');
        }
      }

      setToasts(prevToasts => [
        ...prevToasts,
        options, // Unique ID for each toast
      ]);
    } else {
      setToasts(prevToasts => []);
    }
  };

  // Function to hide toast
  const hideToast = (id) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  };

  // Function to update window size
  const handleResize = () => {
    calcHeight();
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // Function to handle orientation change
  const handleOrientationChange = () => {
    calcHeight();
    setOrientation(window.orientation);
  };

  const isMobile = () => {
    const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return regex.test(navigator.userAgent);
  };

  const calcHeight = () => {
    setTimeout(() => {
      const documentWidth = document.documentElement.clientWidth + 'px';
      const documentHeight = document.documentElement.clientHeight + 'px';
      document.body.style.height = documentHeight;
      document.body.style.width = documentWidth;
      if (isMobile()) {
        document.body.classList.remove('desktop-view');
      } else {
        document.body.classList.add('desktop-view');
      }
    }, 150);
  };

  useEffect(() => {
    // Calculate height initially
    calcHeight();

    // Add event listeners for resize change
    window.addEventListener('resize', handleResize);

    // Add event listener for orientation change
    window.addEventListener('orientationchange', handleOrientationChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return (
    <>
      <SettingsStateProvider>
        <>
          <Router>
            <Routes>
              <Route path='/' element={<MpinVerifyIndex showToast={showToast} />} />
              <Route path='/card-details' element={<CardIndex showToast={showToast} />} />
              <Route path='/forgot-mpin' element={<ForgotMpinIndex showToast={showToast} />} />
              <Route path='/reset-mpin' element={<ResetMpinIndex showToast={showToast} />} />
              <Route path='/change-billing' element={<ChangeBillingIndex showToast={showToast} />} />
              <Route path='/pin-reset' element={<PinResetIndex showToast={showToast} />} />
              <Route path='/coming-soon' element={<ComingSoon showToast={showToast} />} />
              <Route path='/statement-list' element={<StatementsIndex showToast={showToast} />} />
              <Route path='/limit-control' element={<LimitControlsIndex showToast={showToast} />} />
              <Route path='/block-card' element={<BlockCardIndex showToast={showToast} />} />
              <Route path='/card-closure' element={<CardClosureIndex showToast={showToast} />} />
              <Route path='/statement-details' element={<StatementDetailsIndex showToast={showToast} />} />
              <Route path='/unbilled-transactions' element={<UnbilledTransactionsIndex showToast={showToast} />} />
              <Route path='/easy-emi' element={<EasyEmi showToast={showToast} />} />
              <Route path='/transactions-emi' element={<EmiTransactionIndex showToast={showToast} />} />
              <Route path='/about-card' element={<AboutCardIndex showToast={showToast} />} />
              <Route path='/emi-tracking' element={<EmiTrackingIndex showToast={showToast} />} />
            </Routes>
          </Router>
        </>
      </SettingsStateProvider>

      <div className='toast-wrapper'>
        {toasts.map(toast => (
          <ToastNotification
            key={toast.id}
            show={true}
            className={toast?.className}
            message={toast.message} icon={toast?.icon} delay={toast?.delay}
            onClose={() => hideToast(toast.id)}
          />
        ))}
      </div>
    </>
  );
};

export default App;
