import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MpinPopup from "../../../common/mpin-popup/mpin-popup.js";
import { Endpoints } from '../../../common/cms-sdk/api';
import { useLocation } from 'react-router-dom';

export function MenuList({ isCardLocked, cardDetails, cardDetailsApiCall, onLockStatusChange, showToast }) {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [lockUnlockText, setLockUnlockText] = useState(isCardLocked ? 'UnLock' : 'Lock');
    const [modalText, setModalText] = useState(isCardLocked ? 'unlock' : 'lock');
    const location = useLocation();

    useEffect(() => {
        setLockUnlockText(isCardLocked ? 'Unlock' : 'Lock');
        setModalText(isCardLocked ? 'unlock' : 'lock');
    }, [isCardLocked]);

    useEffect(() => {
        console.log('location: ', location?.state);
        if (location?.state?.isStatusChaned) {
            handleActionsChange();
        }
    }, [location]);

    const handleChangeBilling = () => {
        navigate('/change-billing');
    };

    const handleLockCardClick = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleLockCard = async () => {        
        try {
            onLockStatusChange();
            setShowModal(false);
            await cardDetailsApiCall();
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const handleActionsChange = async () => {        
        try {
            onLockStatusChange();
            await cardDetailsApiCall();
            // setShowModal(false); // Close modal only if API call succeeds
        } catch (error) {
            console.log('Error:', error);
        }
    };

    const handlePinReset = () => {
        if (!isCardLocked) {
            navigate('/pin-reset');
        } else {
            showToast('Please unlock your card to avail this service', 'danger');
        }
    };

    const handleLimitControl = () => {
        navigate('/limit-control');
    };

    const handleBlockCard = () => {
        navigate('/block-card')
    };

    const handleCardClosure = () => {
        navigate('/card-closure')
    };

    const handleAboutCard = () => {
        navigate('/about-card')
    }

    return (
        <div className="sidebar-menu-wrapper">
            <div className="sidebar-menu-body">
                <div className="float-menu-item-wrapper" onClick={handleLockCardClick} >
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-lock"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc">{lockUnlockText} your card</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                {showModal && (
                    <MpinPopup modalTitle={lockUnlockText + ' your card'} submitText={'Yes, ' + modalText + ' my card'} onSubmit={handleLockCard} closeModal={closeModal}
                        apiDetails={isCardLocked ? Endpoints.UNLOCK_CARD : Endpoints.LOCK_CARD} showToast={showToast}
                        modalDescription={(isCardLocked ? 'Unlocking' : 'Locking') + ' your card will ' + (isCardLocked ? 'enable' : 'disable') + ' all future transactions and auto-payments. Do you wish to continue?'}></MpinPopup>
                )}

                <div className="float-menu-item-wrapper" onClick={handleLimitControl}>
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="fa-sliders fa-solid"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc" >Set limit and permissions</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handlePinReset}>
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-more-horizontal"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc" >Change your POS/ATM PIN</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handleChangeBilling}>
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-calendar"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc" >Change billing Cycle </div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handleBlockCard}>
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="fa-ban fa-solid"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc">Block your card</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handleCardClosure} >
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-x-circle"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc">Close your card</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
                <div className="float-menu-item-wrapper" onClick={handleAboutCard} >
                    <div className="float-menu-item-container">
                        <div className="item-icon-wrapper"><i className="feather-alert-circle"></i></div>
                        <div className="item-text-wrapper">
                            <div className="item-desc">Know your fees and charges</div>
                        </div>
                        <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                    </div>
                </div>
            </div>
        </div>
    )
}