import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export function UnbilledTransactions(props) {
    const navigate = useNavigate();
    let { transactionsDetails = [] } = props;

    transactionsDetails = transactionsDetails.filter(transaction => transaction.billedStatus === "UNBILLED");
    transactionsDetails.sort((a, b) => new Date(b.transactionDate) - new Date(a.transactionDate));

    const handleTransactionClick = (transaction) => {
        navigate('/transactions-emi', {
            state: {
                description: transaction?.description,
                transactionDate: transaction?.transactionDate,
                amount: transaction?.amount,
                externalTransactionId: transaction?.externalTransactionId,
                billedStatus: transaction?.billedStatus,
                authorizationStatus: transaction?.authorizationStatus,
            }
        });
    };

    const groupedTransactions = transactionsDetails.reduce((acc, transaction) => {
        console.log('acc: ', acc);
        console.log('transaction: ', transaction);
        const date = moment(transaction.transactionDate).format('dddd, Do MMMM YYYY');
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(transaction);
        return acc;
    }, {});
    console.log('groupedTransactions: ', groupedTransactions);

    return (
        <>
            <div className="transactions-wrapper page-wrapper">
                {Object.entries(groupedTransactions).map(([date, transactions], index) => (
                    <div key={index}>
                        <div className="transactions-header">
                            <h4 className="transactions-title">{date}</h4>
                        </div>
                        {transactions.map((transaction, innerIndex) => (
                            <div key={innerIndex} className="transactions-item-wrapper" onClick={() => handleTransactionClick(transaction)}>
                                <div className="transactions-item-container">
                                    <div className="item-icon-wrapper">
                                        <div className={'item-icon-container ' + (transaction?.transactionType === 'DEBIT' ? "debit-transaction" : "credit-transaction")}>
                                            <i className={transaction?.transactionType === 'DEBIT' ? 'fa-minus fa-solid' : 'fa-plus fa-solid'}></i>
                                        </div>
                                    </div>
                                    <div className="item-text-wrapper">
                                        <div className="item-name">{transaction?.description}</div>
                                        <div className="item-time">{moment(transaction?.datetime).format('MMM Do YYYY, h:mm:ss A')}</div>
                                    </div>

                                    <div className="item-value-wrapper">
                                        {transaction.isEmiEligible === true && (
                                            <button className='btn emi-icon'>
                                                <img className='img-fluid' src={require('../../../assets/img/easy_emi_logo.png')} alt="Logo" />
                                            </button>
                                        )}
                                        <div className="item-total-value">
                                            <i className="fa-solid fa-indian-rupee-sign"></i>{transaction?.amount}

                                        </div>

                                    </div>
                                </div>

                                <div className="d-none">
                                    <div className="d-flex">

                                        <div className={`transaction-icon-circle ${transaction.transactionType === "DEBIT" ? 'debit' : 'credit'}`}>
                                            {transaction.description ? transaction.description.charAt(0).toUpperCase() : ''}
                                        </div>
                                        <div>
                                            <h2 className="transaction-container-heading">{transaction?.description ? transaction.description.split('|')[0].trim() : ''}</h2>
                                            <p className="transaction-container-time">{moment(transaction.transactionDate).format('Do MMM YYYY hh:mm A')}</p>
                                            <p className="transaction-container-time">{transaction.transactionDate}</p>
                                        </div>
                                    </div>
                                    <div className='transaction-card-right-wrapper'>
                                        <div>
                                            {transaction.isEMIEligible !== false && (
                                                <img className='logo' src={require('../../../assets/img/easy_emi_logo.png')} alt="Logo" />
                                            )}
                                        </div>

                                        <div className="unbilled-transaction-container-amount">
                                            {transaction.transactionType === "CREDIT" && "+ "}
                                            &#8377;{parseFloat(transaction.amount).toFixed(2)} &nbsp;
                                            <i className="fa-solid fa-chevron-right unbilled-transaction-container-amount-i"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </>
    );
}
