import React from 'react';
import { Header } from './components/header.js';
import { CardClosure } from './components/card-closure.js';

export function CardClosureIndex({showToast}) {
    

    return (
        <>
            <Header />
            <CardClosure showToast={showToast} />
        </>
    )
}
