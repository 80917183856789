import React from "react";

export function AboutCard() {



    const cardBenefits = [
        {
            "id": "lets_talk_fees",
            "title": "Let's talk fees",
            "type": "talk-fees",
            "position": "left",
            "data": [
                {
                    "title": "Zero Joining Fees",
                    "description": "Pay nothing when you join",
                    "type": "danger-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa-0"
                    }
                },
                {
                    "title": "No annual renewal fees",
                    "description": "Enjoy the card year on year, at no cost",
                    "type": "danger-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa-0"
                    }
                },
                {
                    "title": "Zero forex markup",
                    "description": "Swipe for no extra fees",
                    "type": "danger-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa-0"
                    }
                }
            ]
        },
        {
            "id": "lets_talk_payments",
            "title": "Let's talk payments",
            "type": "talk-payments",
            "position": "left",
            "data": [
                {
                    "title": "Get up to 48 days of no interest",
                    "description": "With a billing cycle of 30 days & payment period of 18 days",
                    "type": "primary-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa-calendar-check"
                    }
                },
                {
                    "title": "Convert to EMIs",
                    "description": "16% interest rate applicable for 3, 6, 9, 12 month tenures",
                    "type": "primary-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa-percent"
                    }
                },
                {
                    "title": "Pay at your pace at 3.49%",
                    "description": "Can't pay the due within 48 days? Pay slowly, at an interest of 3.49% per month",
                    "type": "primary-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa-percent"
                    }
                },
                {
                    "title": "Minimum amount due",
                    "description": "Pay 5% of your outstanding amount + other charges",
                    "type": "primary-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa-percent"
                    }
                },
                {
                    "title": "Late payment charges",
                    "description": "Late payment charges depend on balance due",
                    "type": "primary-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa fa-inr"
                    },
                    "linkDetails": {
                        "text": "See details",
                        "type": "late_payment_charges"
                    }
                },
                {
                    "title": "Billing cycle",
                    "description": "Billing cycle starts on 25th of every month. Pay by 13th of next month",
                    "type": "primary-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa-solid fa-arrow-right-arrow-left"
                    },
                    "linkDetails": {
                        "text": "Change cycle",
                        "type": "change_cycle"
                    }
                }
            ]
        },
        {
            "id": "need_cash",
            "title": "Need Cash? No Problem!",
            "type": "need-cash",
            "position": "right",
            "data": [
                {
                    "title": "SOS! I need to withdraw cash",
                    "description": "Go ahead! Withdraw up to 10% of your approved credit limit",
                    "type": "success-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa fa-percent"
                    }
                },
                {
                    "title": "Cash withdrawal fee",
                    "description": "2.5% of amount withdrawn, at a minimum of ₹500",
                    "type": "success-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa fa-percent"
                    }
                },
                {
                    "title": "Cash withdrawal interest",
                    "description": "3.49% per month from the date of withdrawal",
                    "type": "success-gradient",
                    "iconStyle": "circle",
                    "iconPosition": "left-icon",
                    "assets": {
                        "type": "icon",
                        "name": "fa fa-percent"
                    }
                }
            ]
        }
    ];


    return (
        <>
            <div className="body-wrapper has-padding">
                <div className="body-container">
                    <div className="card-name-container">
                        <h2>Card name</h2>
                        <h3>UNITY CARD</h3>
                    </div>

                    <div className="card-benefit-wrapper">
                        {cardBenefits.map((benefit, index) => (
                            <div className="card-benefit-item">
                                <h5 class="benefit-card-title">{benefit.title}</h5>
                                {benefit.data.map((benefitCard, benefitCardIndex) => (
                                    <>
                                        <div className="benefit-card-wrapper">
                                            <div className="custom-card-content-wrapper">
                                                <div className="title">{benefitCard.title}</div>
                                                <div className="description">{benefitCard.description}</div>
                                                {benefitCard?.linkDetails?.type &&
                                                    <a href="javascript:void(0)" class="link">{benefitCard?.linkDetails?.text}</a>
                                                }
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
