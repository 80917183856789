export const BaseURL =  'https://dev-cms.saven.in'// 'backend-server.apps.svc.cluster.local:3000'  
// export const BaseURL =  'https://demo-cms.saven.in'

export const Endpoints = {
    Authentication: '/user/verify/mpin',
    ResetMpin: '/card/reset/mpin',
    GetBalance: '/card/get/balance',
    GetCardList: '/card/get/list',
    GetCardDetails: '/card/get/details',
    GetCvv: '/card/get/cvv',
    SetLimit: '/card/set/limit',
    GetLimit: '/card/get/limit',
    UpgradeLimit: '/card/upgrade/limit',
    GetPreference: '/card/get/preference',
    SetPreference: '/card/set/preference',
    GetStatement: '/card/get/statement',
    GetUnbilledTransactions: '/card/get/unbilled/transactions',
    GetDue: '/card/get/due',
    UpdateStatement: '/card/update/statement/date',
    SetPin: '/card/set/pin',
    GetBillingDates: '/card/get/billing_dates',
    LOCK_CARD: '/card/lock',
    UNLOCK_CARD: '/card/unlock',
    REPLACE_CARD: '/card/replace',
    RequestPhysicalCard: '/request/physical/card',
    BLOCK_CARD: '/card/block',
    CLOSE_CARD: '/card/close',
    EmiEligibleTransactions: '/loan/eligible/emi/transactions',
    EmiEligibleList: '/loan/get/list',
    EmiPreview: '/loan/preview',
    GetTransactions: '/card/get/transactions',
    PreviousStatements: '/card/get/previous/statements',
    OTP_SEND: '/otp/send',
    USER_FORGOT_MPIN: '/user/forgot/mpin',
    TOKEN_GENERATE: '/token/generate'
}