import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { StatementDetailsHeader } from './components/statement-details-header.js';
import { StatementDetailsMenu } from './components/statement-details-menu.js';
import { StatementDetailsTransactions } from './components/statement-details-transactions.js';
import { ApiCalls } from '../common/apiCalls.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { CommonValues } from "../common/commonValues.js";

export function StatementDetailsIndex() {
    const location = useLocation();
    let [loading, setLoading] = useState(false);

    const statementData = location.state && location.state.statementData;
    const transactions = location.state && location.state.transactions;

    const { loginUserData } = CommonValues();
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 200);
    }, [loginUserData]);

    return (
        <>
            {loading ? (
                <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '45%' }} />
                </div>
            ) : (
                <>
                    <StatementDetailsHeader statementData={statementData?.startDate} />
                    <div className="body-wrapper has-sticky-header">
                        <div className="body-container">
                            <StatementDetailsMenu statementData={statementData} />
                            <StatementDetailsTransactions transactions={transactions} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}
