import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

export function CommonValues() {
    let [isInitial, setInitial] = useState(false);
    let [loginUserData, setLoginUserData] = useState();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        // Parse query params from the current URL
        const params = new URLSearchParams(location.search);
        let queryObject = {};
        params.forEach((value, key) => {
            queryObject[key] = value.trim();
        });
        let isLocal = true;
        if (!queryObject.mobile) {
            if ((window.location.hostname === 'localhost')) {
                // isLocal = true;
            }
        }

        if (isLocal) {
            queryObject = {
                mobile: '9030235945'
            }
        }

        if (queryObject.mobile) {
            queryObject.countryCode = '+91';
            queryObject.mobileNumber = queryObject.countryCode + queryObject.mobile;
        }
        console.log('queryObject: ', queryObject);
        setLoginUserData(queryObject);
        if (location.search) {
            // navigate(window.location.pathname, { replace: true });
        }
        if (!queryObject.mobileNumber) {
            if ((window.location.hostname === 'localhost')) {
                // window.location.href = '/';
            } else {
                window.location.href = process.env.REACT_APP_ONBOARDING_HOST_URL+'cms';
            }
        }

        // Only run once on component mount or when the URL changes (location.search)
    }, [isInitial]);

    useEffect(() => {
        // Log the updated loginUserData whenever it changes
        console.log('loginUserData updated: ', loginUserData);
    }, [loginUserData]);

    return { loginUserData };
}
