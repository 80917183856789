import React from 'react';
import { useNavigate } from 'react-router-dom';

const UserProfileSidebar = ({ cardDetails, isOpen, toggleSidebar }) => {
    const navigate = useNavigate();

    const handleMenuClick = (type) => {
        // navigate('/');
        console.log('type: ', type);
        if (type === 'home') {
            toggleSidebar();
        }
        else if (type === 'logout') {
            localStorage.removeItem('authToken');
            if ((window.location.hostname === 'localhost')) {
                window.location.href = '/';
            } else {
                window.location.href = process.env.REACT_APP_ONBOARDING_HOST_URL+'cms?isLogout=true';
            }
        }
    };

    return (
        <>
            {isOpen ? (
                <div className="sidebar-wrapper">
                    <div className="sidebar-container">
                        <div className="back-btn-wrapper">
                            <button className="btn" onClick={toggleSidebar}>
                                <i className="feather-arrow-left"></i>
                            </button>
                        </div>
                        {/* <div className="close-btn-wrapper">
                            <button className="btn" onClick={toggleSidebar}>
                                <i className="feather-x"></i>
                            </button>
                        </div> */}
                        <div className="sidebar-body-wrapper">
                            <div className='user-menu-wrapper'>
                                <div className="user-wrapper">
                                    <div className="user-icon-wrapper"><i className="fa-regular fa-user user-icon"></i></div>
                                </div>
                                <div className="user-details-wrapper">
                                    <div className="user-name">{cardDetails?.customer?.name}</div>
                                </div>
                            </div>

                            {/* <div className="float-menu-item-wrapper" onClick={() => handleMenuClick('home')}>
                                <div className="float-menu-item-container">
                                    <div className="item-icon-wrapper"><i className="feather-home"></i></div>
                                    <div className="item-text-wrapper">
                                        <div className="item-desc" >Home</div>
                                    </div>
                                    <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                                </div>
                            </div> */}
                            <div className="float-menu-item-wrapper" onClick={() => handleMenuClick('logout')}>
                                <div className="float-menu-item-container">
                                    <div className="item-icon-wrapper"><i className="feather-log-out"></i></div>
                                    <div className="item-text-wrapper">
                                        <div className="item-desc" >Logout</div>
                                    </div>
                                    <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div>
                                </div>
                            </div>
                            <div className="float-menu-item-wrapper">
                                <div className="float-menu-item-container">
                                    <div className="item-icon-wrapper"><i className="feather-check-circle"></i></div>
                                    <div className="item-text-wrapper">
                                        <div className="item-desc" >App Version 1.0.0</div>
                                    </div>
                                    {/* <div className="item-arrow-wrapper"><i className="feather-chevron-right"></i></div> */}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="sidebar-overlay" onClick={toggleSidebar}></div>
                </div>
            ) : ''}
        </>
    );
};

export default UserProfileSidebar;