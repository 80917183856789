import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MpinPopup from "../../../common/mpin-popup/mpin-popup.js";
import { Endpoints } from '../../../common/cms-sdk/api';

export function Menu({ blockCardApiCall, showToast }) {
    const [selectedOption, setSelectedOption] = useState('');
    const [isOptionSelected, setIsOptionSelected] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();

    const reasonList = [
        'Lost my card',
        'Card security is compromised',
        'Card is damaged'
    ];

    useEffect(() => {
        setIsOptionSelected(selectedOption !== '');
    }, [selectedOption]);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };
    const handleConfirmClick = () => {
        setShowModal(true);
    }

    const handleBlockCard1 = async () => {
        // try {
        //     await blockCardApiCall();
        //     setShowSuccessMessage(true);
        // } catch (error) {
        //     setShowErrorMessage(true);
        //     return;
        // }
        // setTimeout(() => {
        //     setShowSuccessMessage(false);
        //     setShowErrorMessage(false);
        //     navigate(-1);
        // }, 3000);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleBlockCard = async () => {
        setShowModal(false);
        navigate(-1, { state: { isStatusChaned: true } });
    };

    return (
        <>
            <div className="body-wrapper has-padding has-fixed-footer">
                <div className="body-container">
                    <div className="page-title-wrapper">
                        <div className="page-title">Block your card permanently</div>
                    </div>

                    <div className="">
                        <label className="form-label">I want to block my card because:</label>
                        <div className="pt-1">
                            {reasonList.map((resonItem, index) => (
                                <div className="custom-radio-item-wrapper" key={'resonItem_' + index}>
                                    <label className="form-check-radio" htmlFor={'reson_' + index}>
                                        <input type="radio" name="closureReason" id={'reson_' + index} className="form-check-input-radio"
                                            onClick={() => handleOptionChange(resonItem)} />
                                        <span>{resonItem}</span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>

                <div className='page-footer-wrapper'>
                    <div className='page-footer-container'>
                        <button className="btn btn-primary btn-lg btn-circular page-footer-btn" onClick={handleConfirmClick} disabled={!isOptionSelected}>
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
            {showModal && (
                <MpinPopup modalTitle={'Block your card'} submitText={'Yes, block my card'} onSubmit={handleBlockCard} closeModal={closeModal}
                    apiDetails={Endpoints.BLOCK_CARD} showToast={showToast} actionReason={selectedOption}
                    modalDescription={''}></MpinPopup>
            )}
        </>
    );
}