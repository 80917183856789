import React from 'react';
import { useNavigate } from "react-router-dom";

export function StatementList(props) {
    const navigate = useNavigate();
    let { previousStatements = [] } = props;

    const formatDate = (startDate, endDate) => {

        const startDateObj = new Date(startDate);

        const endDateObj = calculateEndDate(startDateObj);

        const formattedStartDate = formatDatePart(startDateObj);
        const formattedEndDate = formatDatePart(endDateObj);

        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    const calculateEndDate = (startDateObj) => {
        const endDateObj = new Date(startDateObj);
        endDateObj.setMonth(startDateObj.getMonth() + 1);
        endDateObj.setDate(startDateObj.getDate() - 1);
        return endDateObj;
    }

    const formatDatePart = (dateObj) => {
        const day = dateObj.getDate();
        const month = dateObj.toLocaleString('en-US', { month: 'short' });
        const year = dateObj.getFullYear();
        const ordinal = getOrdinal(day);
        return `${day}${ordinal} ${month} ${year}`;
    }

    const getOrdinal = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1: return "st";
            case 2: return "nd";
            case 3: return "rd";
            default: return "th";
        }
    }
    previousStatements.sort((a, b) => new Date(b.startDate) - new Date(a.startDate));

    const handleStatementClick = (statement) => {
        navigate('/statement-details', {
            state: {
                statementData: statement,
                transactions: statement.transactions
            }
        });
    };

    return (
        <div className="statement-container">
            <div className='page-wrapper'>
                {previousStatements.map((statement, index) => (
                    <React.Fragment key={index}>
                        {index === 0 ? (
                            <h4 className="statement-heading pt-0">Latest Statement</h4>)
                            : index === 1 ? (
                                <h4 className="statement-heading">Past Statement</h4>
                            ) : ''}

                        <div className="statement-card-wrapper" onClick={() => handleStatementClick(statement)}>
                            <div className='statement-card-container'>
                                <div className='statement-icon-wrapper'>
                                    <div className='statement-icon-container'>
                                        <i className="fa-regular fa-credit-card"></i>
                                    </div>
                                </div>
                                <div className='statement-card-content-wrapper'>
                                    <div className="statement-duration">{formatDate(statement.startDate)}</div>
                                    <div className='statement-card-value-wrapper'>
                                        <div className="statement-text-wrapper">&#8377;{statement.currentStatementAmount}</div>
                                        <div className='statement-card-status-wrapper'>

                                            {statement.paymentStatus === 'PAID' && (
                                                <div className="statement-bill-info">Bill paid fully on {statement.paymentDate}</div>
                                            )}
                                            {statement.paymentStatus === 'UNPAID' && (
                                                <div className="statement-bill-info unpaid">Bill not paid</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="statement-details d-flex align-items-center">
                                    <div className="icon-container">
                                        <i className="fa-regular fa-credit-card"></i>
                                    </div>
                                    <div className="amount">&#8377;{statement.currentStatementAmount}</div>
                                </div> */}
                            </div>
                        </div>
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
}
