export const balanceDetails = {
    "status": "success",
    "data": {
        "productId": "GENERAL",
        "balanceAmount": "6788.0",
        "lienBalanceAmount": "0.0"
    }
}

export const cardDetails = {
    "status": "success",
    "data": {
        "card": {
            "id": "",
            "kitNo": "4000000771",
            "cardNo": "4666560200300771",
            "partialCardNo": "0771",
            "isPinSetup": true,
            "type": "VIRTUAL",
            "networkType": "VISA",
            "status": "ALLOCATED",
            "expiryDate": "2602",
            "issueDate": "2024-10-01"
        },
        "customer": {
            "name": "khadeer khadeer",
            "dob": "01012000"
        }
    }
}

export const cardList = {
    "status": "success",
    "data": [
        {
            "kitNo": "93440000315",
            "cardNo": "3561670000001125",
            "partialCardNo": "1125",
            "isPinSetup": false,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000324",
            "cardNo": "3561670000001134",
            "partialCardNo": "1134",
            "isPinSetup": true,
            "cardType": "PHYSICAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000332",
            "cardNo": "3561670000001142",
            "partialCardNo": "1142",
            "isPinSetup": false,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000341",
            "cardNo": "3561670000001151",
            "partialCardNo": "1151",
            "isPinSetup": true,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000349",
            "cardNo": "3561670000001159",
            "partialCardNo": "1159",
            "isPinSetup": true,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "LOCKED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "93440000351",
            "cardNo": "3561670000001161",
            "partialCardNo": "1161",
            "isPinSetup": true,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2710",
            "cardIssueDate": null
        },
        {
            "kitNo": "15870000051",
            "cardNo": "6529560000000515",
            "partialCardNo": "0515",
            "isPinSetup": false,
            "cardType": "VIRTUAL",
            "networkType": "RUPAY",
            "status": "REPLACED",
            "cardExpiry": "2811",
            "cardIssueDate": null
        }
    ]
}

export const transactionDetails = {
    "status": "success",
    "data": [
        {
            "id": "",
            "amount": 500,
            "date": "2024-10-08",
            "time": "12:52:53",
            "datetime": 1728391973000,
            "externalId": "010054101106271211500000043327400000570000",
            "internalId": "4203039496149382",
            "origin": "POS",
            "kitNo": "4000000771",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "500.00",
            "isEmiEligible": false
        },
        {
            "id": "",
            "amount": 250,
            "date": "2024-10-08",
            "time": "12:51:47",
            "datetime": 1728391907000,
            "externalId": "010019700306271211500000043327400000570000",
            "internalId": "2200904348622486",
            "origin": "POS",
            "kitNo": "4000000771",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "250.00",
            "isEmiEligible": false
        },
        {
            "id": "",
            "amount": 10000,
            "date": "2024-10-03",
            "time": "09:58:41",
            "datetime": 1727949521000,
            "externalId": "Repayment_141223456_12_v1",
            "internalId": "698894706",
            "origin": "MOBILE",
            "kitNo": "4000000771",
            "description": "Repayment - Repayment",
            "merchantName": "Repayment",
            "merchantLocation": "nt",
            "type": "CREDIT",
            "category": "DIRECT_CREDIT",
            "subCategory": "DIRECT_CREDIT",
            "universalCurrencyAmount": "10000.00",
            "isEmiEligible": false
        },
        {
            "id": "",
            "amount": 65,
            "date": "2024-10-03",
            "time": "06:22:36",
            "datetime": 1727936556000,
            "externalId": "010057900606271211500000043327400000570000",
            "internalId": "8326911338675431",
            "origin": "POS",
            "kitNo": "4000000771",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "65.00",
            "isEmiEligible": false
        },
        {
            "id": "",
            "amount": 897,
            "date": "2024-10-03",
            "time": "05:09:44",
            "datetime": 1727932184000,
            "externalId": "010060204106271211500000043327400000570000",
            "internalId": "3151487007949563",
            "origin": "POS",
            "kitNo": "4000000771",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "897.00",
            "isEmiEligible": false
        },
        {
            "id": "",
            "amount": 1000,
            "date": "2024-10-03",
            "time": "05:08:34",
            "datetime": 1727932114000,
            "externalId": "010076957906271211500000043327400000570000",
            "internalId": "7615189032345032",
            "origin": "POS",
            "kitNo": "4000000771",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "1000.00",
            "isEmiEligible": false
        },
        {
            "id": "",
            "amount": 500,
            "date": "2024-10-02",
            "time": "15:26:17",
            "datetime": 1727882777000,
            "externalId": "010020877606271211500000043327400000570000",
            "internalId": "6099525247133962",
            "origin": "POS",
            "kitNo": "4000000771",
            "description": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantName": "ABCRESTAURANTCOIMBATORE               IN",
            "merchantLocation": "IN",
            "type": "DEBIT",
            "category": "POS",
            "subCategory": "POS",
            "universalCurrencyAmount": "500.00",
            "isEmiEligible": false
        }
    ]
}

export const cvvDetails = {
    "status": "success",
    "data": {
        "cvv": "299"
    }
}

export const limitDetails = {
    "status": "success",
    "data": {
        "totalLimit": "0.00",
        "availableLimit": "6788.00",
        "utilizedLimit": "-6788.00",
        "cashLimit": "0.00",
        "creditBalance": "6788.00"
    }
}

//GET Call to identify the due amount and details for a user card
export const dueDetails = {
    "status": "success",
    "data": {
        "minDue": "131.90",
        "totalDue": "0.00",
        "dueDate": "2024-01-06",
        "paymentMade": "141.00",
        "unbilledAmount": "12166.00",
        "interestAccumulated": "0.00",
        "totalOutStandingAmount": "7503.00",
        "currentStatementDate": "2024-03-21",
        "nextStatementDate": "2024-03-04",
        "unpaidMinDue": "0.00",
        "unpaidTotalDue": "2497.00",
        "unpaidMinDueBeforeDue": "131.90",
        "unpaidTotalDueBeforeDue": "2638.00",
        "excessPayment": "0.00",
        "postedEmi": {
            "emiTotalAmount": "0.00",
            "principal": "0.00",
            "interest": "0.00",
            "otherCharges": "0.00"
        },
        "unpaidPostedEmi": {
            "emiTotalAmount": "0.00",
            "principal": "0",
            "interest": "0",
            "otherCharges": "0"
        },
        "previousUnpaidEmi": {
            "emiTotalAmount": "0.00",
            "principal": "0",
            "interest": "0",
            "otherCharges": "0"
        },
        "feeDetails": [
            {
                "feeType": "LATEPAYMENT",
                "unPaidBilledFee": "0.00",
                "unPaidUnBilledFee": "500.00",
                "unPaidBilledFeeTax": "0.00",
                "unPaidUnBilledFeeTax": "90.00"
            }
        ],
        "lastStatementAmount": "2638.00",
        "repaymentPercentage": null
    }
}

export const statementDetails = {
    "status": "success",
    "data": {
        "transactions": [
            {
                "date": "2024-02-14 06:22:13",
                "type": "DEBIT",
                "externalId": "010035439706271211500000043327400000570000",
                "internalId": "404511502421",
                "amount": 2691,
                "category": "POS",
                "subCategory": "POS",
                "description": "ABCRESTAURANTCOIMBATORE               IN",
                "status": "SUCCESS"
            },
            {
                "date": "2024-02-14 06:22:14",
                "type": "CREDIT",
                "externalId": "8303783561705693390",
                "internalId": "839792370",
                "amount": 53,
                "category": "DIRECT_CREDIT",
                "subCategory": "REWARDS_CASHBACK",
                "description": "REWARDS_CASHBACK",
                "status": "SUCCESS"
            }
        ],
        "statement": {
            "amount": 0,
            "totalCreditAmount": 0,
            "totalDebitAmount": 0,
            "minimumDueAmount": 131.9,
            "statementDate": "2023-12-04 00:00:00",
            "startDate": "2023-11-04 00:00:00",
            "customerDueDate": "2023-12-06 18:20:00",
            "lastStatementBalance": 2638,
            "paymentStatus": "PAID",
            "finance": {
                "interest": 0,
                "tax": 0,
                "fees": 0
            },
            "purchase": {
                "amount": 0,
                "cash": 0
            },
            "emi": {
                "debit": 0,
                "principle": 0,
                "interest": 0,
                "otherCharges": 0,
                "limitBlockedAmount": 0
            }
        }
    }
}

export const updateStatementResponse = {
    "status": "success",
    "data": {
        "status": true
    }
}

export const setNewPinResponse = {
    "status": "success",
    "data": {
        "status": true
    }
}

export const billingDates = {
    "status": "success",
    "data": [
        {
            "from": 7,
            "to": 25,
            "eligible": false
        },
        {
            "from": 15,
            "to": 3,
            "eligible": true
        },
        {
            "from": 20,
            "to": 8,
            "eligible": true
        },
        {
            "from": 21,
            "to": 9,
            "eligible": true
        },
        {
            "from": 25,
            "to": 15,
            "eligible": true
        }
    ]
}