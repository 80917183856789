import React from 'react';
import { UnbilledTransactionsHeader } from './components/unbilled-transactions-header';
import { UnbilledTransactions } from './components/unbilled-transactions';
import { ApiCalls } from '../common/apiCalls';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export function UnbilledTransactionsIndex() {
    const { transactionsDetails, loading } = ApiCalls();

    return (
        <>
            <UnbilledTransactionsHeader />
            <div className="body-wrapper has-sticky-header">
                <div className="secondary-header-wrapper">
                    <div>View all your unbilled transactions below</div>
                </div>
                <div className="body-container">
                    {loading ? (
                        <div className="loader">
                            <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '40%' }} />
                        </div>
                    ) : (
                        <>
                            <UnbilledTransactions transactionsDetails={transactionsDetails} />

                        </>
                    )}
                </div>
            </div>
        </>
    );
}
