import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

export function Header({ headerTitle, isBack }) {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    if (isBack) {
      navigate(-1);
    } else {
      navigate(-1);
    }
  }

  return (
    <div className="header-wrapper back-header">
      <div className="header-container">
        <div className="header-menu-wrapper">          
        <button className="btn" onClick={handleBackButtonClick}>
              <i className="feather-arrow-left"></i>
            </button>
        </div>
        <div className="header-title">{headerTitle}</div>
        <div className="header-right-wrapper"></div>
      </div>
    </div>
  )
}