import React, { useState } from 'react';
import { SettingsIndex } from '../../settings/settingsIndex';
import UserProfileSidebar from './user-profile-sidebar'; // Import the UserProfileSidebar component

function Header(props) {
    const { settingmodalOpen, setSettingModalOpen, setButtonClick, showToast, cardLoading } = props;
    const [isUserProfileSidebarOpen, setUserProfileSidebarOpen] = useState(false);

    const handleCloseCardSettings = (isCardStatusChanged) => {
        setSettingModalOpen(false);
        if (isCardStatusChanged) {
            setButtonClick(true);
        }
    };

    const toggleSidebar = () => {
        setUserProfileSidebarOpen(!isUserProfileSidebarOpen);
    };

    const handleButtonClick = () => {
        setSettingModalOpen(true);
    };

    const handleRefresh = () => {
        setButtonClick(true);
    };

    const handleUserProfileClick = () => {
        setUserProfileSidebarOpen(true);
    };

    return (
        <>
            <div className="header-wrapper">
                <div className="header-container">
                    <div className="header-menu-wrapper">
                        <div className="user-wrapper">
                            <div className="user-icon-wrapper" onClick={handleUserProfileClick}>
                                <i className="fa-regular fa-user user-icon"></i>
                            </div>
                            <div className="user-details-wrapper">
                                <div className="user-name">Hi, {props.cardDetails?.customer?.name ? props.cardDetails?.customer.name.split(' ')[0] : ''}</div>
                            </div>
                        </div>
                    </div>
                    <div className="header-right-wrapper">
                        <button className="btn" disabled={cardLoading}>
                            <i className="feather-refresh-cw" onClick={handleRefresh}></i>
                        </button>
                        <button className="btn" disabled={cardLoading}>
                            <i className="feather-settings" onClick={handleButtonClick}></i>
                        </button>
                    </div>
                </div>
            </div>

            {settingmodalOpen ? (
                <SettingsIndex onClose={handleCloseCardSettings} showToast={props.showToast.showToast} />
            ) : ''}

            {/* Sidebar component */}
            {isUserProfileSidebarOpen ? (
                <UserProfileSidebar cardDetails={props.cardDetails} isOpen={isUserProfileSidebarOpen} toggleSidebar={toggleSidebar} />
            ) : ''}
        </>
    );
}

export default Header;