import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { cmsUserAuthenticate } from '../../../common/cms-sdk/index';
import { CommonValues } from "../../common/commonValues.js";

export const MpinLogin = ({ showToast }) => {
  const { loginUserData } = CommonValues();
  const [mPin, setMPin] = useState(["", "", "", ""]);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [firstAttempt, setFirstAttempt] = useState(true);
  const [timerRunning, setTimerRunning] = useState(false);
  const [timerValue, setTimerValue] = useState(0);

  const inputRefs = useRef([]);
  const submitButtonRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  useEffect(() => {
    const canLogin = (!timerRunning && !firstAttempt && mPin.every(digit => digit !== "") && mPin.length === 4) || (firstAttempt && mPin.every(digit => digit !== ""));
    setLoginDisabled(!canLogin);
  }, [timerRunning, firstAttempt, mPin]);

  const handlePinChange = useCallback((index, value) => {
    if (timerRunning) return;
    if (!isNaN(value)) {
      const newMPin = [...mPin];
      newMPin[index] = value;
      setMPin(newMPin);
      if (value === "" && index > 0) {
        inputRefs.current[index - 1].focus();
      } else if (value !== "" && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  }, [timerRunning, mPin]);

  const handleBackspace = useCallback((index) => {
    if (index === 0) {
      const newMPin = [...mPin];
      newMPin[index] = "";
      setMPin(newMPin);
    } else {
      const newMPin = [...mPin];
      newMPin[index] = "";
      setMPin(newMPin);
      inputRefs.current[index - 1].focus();
    }
  }, [timerRunning, mPin]);

  const handleLogin = useCallback(async () => {
    setFirstAttempt(false);
    const authenticateUser = await cmsUserAuthenticate(mPin.join(''), loginUserData.mobileNumber);
    if (authenticateUser === true) {
      showToast('M-PIN login successful.', 'success');
      navigate('/card-details');
    } else {
      showToast('Incorrect M-PIN.', 'danger');
      setMPin(["", "", "", ""]);
      setLoginDisabled(true);
      setTimerRunning(true);
      setTimerValue(7);
      const timerInterval = setInterval(() => {
        setTimerValue(prevValue => prevValue - 1);
      }, 1000);
      setTimeout(() => {
        clearInterval(timerInterval);
        setTimerRunning(false);
      }, 7000);
    }
  }, [mPin, navigate]);

  useEffect(() => {
    if (!timerRunning) {
      inputRefs.current[0].focus();
    }
  }, [timerRunning]);


  const handleForgotPin = () => {
    navigate('/forgot-mpin');
  }

  const handleEnterKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !loginDisabled) {
      handleLogin();
    }
  }, [loginDisabled, handleLogin]);

  const pinInputs = useMemo(() => (
    mPin.map((digit, index) => (
      <div className="pin-item" key={index}>
        <input
          type="password"
          value={digit ? '*' : ''}
          maxLength={1}
          onChange={(e) => handlePinChange(index, e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Backspace") {
              e.preventDefault();
              handleBackspace(index);
            } else {
              handleEnterKeyPress(e);
            }
          }}
          ref={(ref) => (inputRefs.current[index] = ref)}
          className="form-control"
          inputMode="numeric"
          disabled={timerRunning}
        />
      </div>
    ))
  ), [mPin, handlePinChange, handleEnterKeyPress, handleBackspace, timerRunning]);

  return (
    <div className="body-wrapper no-header login-body-wrapper">
      {/* <div className="overlay-blur"></div> */}
      <div className="body-container">
        <div className="m-pin-wrapper">
          <div className="page-title-wrapper">
            <h3 className="page-title">Enter M-PIN</h3>
          </div>
          <div className="page-middle-wrapper">
            <p className="page-description">Enter your M-PIN for a secure entry to your card</p>
            <div className="set-pin-container">
              <div className="set-pin-item-wrapper">
                <div className="set-pin-item-container">{pinInputs}</div>
              </div>
            </div>

            {timerRunning &&
              <div className="alert-item-wrapper danger-alert mb-2">
                <div className="alert-item-container">
                  <div className="alert-text">Try again in {timerValue} seconds</div>
                </div>
              </div>
            }

            <div className="footer-forgot-wrapper">
              <button
                className="btn btn-link btn-link-strong"
                onClick={handleForgotPin}
                disabled={timerRunning}
              >Forgot M-Pin
              </button>
            </div>
          </div>

          <div className="page-footer-wrapper">
            <button
              ref={submitButtonRef} // Reference to the submit button
              className="btn btn-primary btn-lg btn-circular page-footer-btn"
              onClick={handleLogin}
              disabled={loginDisabled || timerRunning}
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
