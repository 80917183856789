import { useState, useEffect } from 'react';
import { fetchPreviousStatements } from '../../common/cms-sdk/index';

export function PreviousStatementsApis() {
    let [previousStatements, setPreviousStatements] = useState([]);
    let [previousStatemenLoading, setPreviousStatementsLoading] = useState(true);
    let [statementApisCalls, setStatementApisCalls] = useState(false);

    async function PreviousStatementApicall() {
        setPreviousStatementsLoading(true);
        console.log('getMonth########: ', new Date().getMonth());
        console.log('getFullYear########: ', new Date().getFullYear());
        const month = new Date().getMonth()+1;
        let monthYear = (month>9?month:'0'+month)+''+ new Date().getFullYear();
        console.log('monthYear########: ', {monthYear});
        const previousStatementsData = await fetchPreviousStatements(monthYear);
        setPreviousStatements(previousStatementsData);

        setPreviousStatementsLoading(false);
        console.log('previousStatemenLoading: ', previousStatemenLoading);
    }

    useEffect(() => {
        PreviousStatementApicall();
        setStatementApisCalls(false);
    }, [statementApisCalls]);

    return { statementApisCalls, setStatementApisCalls, previousStatements, previousStatemenLoading };
}
