import React from 'react';
import { useNavigate } from 'react-router-dom';

export function EasyEmi() {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    }

    return (
        <>
            <div className="header-wrapper back-header">
                <div className="header-container">
                    <div className="header-menu-wrapper"><button className="btn" onClick={handleBackClick}><i className="feather-arrow-left"></i></button></div>
                    <div className="header-title">Transaction 1</div>
                    <div className="header-right-wrapper">
                        {/* <button className="btn"><i className="fas fa-ellipsis-v"></i></button> */}
                    </div>
                </div>
            </div>
            <div className='body-wrapper'>
                <div className='body-container'>
                    <div className='transactions-wrapper'>

                        <div className='statement-card-wrapper'>
                            <div className='statement-card-container'>
                                <div className='statement-icon-wrapper'>
                                    <div className='statement-icon-container'>
                                        <i className="fa-regular fa-credit-card"></i>
                                    </div>
                                </div>
                                <div className='statement-card-content-wrapper'>
                                    <div className="statement-duration">11th Mar 2024 . 09.03 AM</div>
                                    <div className='statement-card-value-wrapper'>
                                        <div className="statement-text-wrapper">₹2,500.80</div>
                                        <div className='statement-card-status-wrapper'></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='transactions-wrapper'>
                        <div className="transaction-label-wrapper">
                            <div className='transaction-label-container'>
                                <p className="transaction-label-title">Transaction status</p>
                                <p className="transaction-label-value label-title success-text">Pending settlement</p>
                            </div>
                            <p className='transaction-label-description'>(Usually takes 2-3 working days to reflect in your account)</p>
                        </div>
                        <div className="transaction-label-wrapper">
                            <div className='transaction-label-container'>
                                <p className="transaction-label-title">Billing status</p>
                                <p className="transaction-label-value label-title">Not Billed</p>
                            </div>
                        </div>
                        <div className="transaction-label-wrapper">
                            <div className='transaction-label-container'>
                                <p className="transaction-label-title">Transaction ID</p>
                                <p className="transaction-label-value label-title">XXXXXXXXXXXXXXX
                                    <button className="btn btn-link"><i className="fa-regular fa-copy copy-icon"></i></button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
