import React from "react";
import { useNavigate } from "react-router-dom";

export function Header(props) {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    navigate(-1);
  }

  return (
    <div className="header-wrapper back-header">
      <div className="header-container">
        <div className="header-menu-wrapper">
          <button className="btn" onClick={handleBackButtonClick} >
            <i className="feather-arrow-left"></i>
          </button>
        </div>
        <div className="header-title">
          <img className='logo-header' src={require('../../../../src/assets/img/easy_emi_header.png')} alt="Logo" />
        </div>
        <div className="header-right-wrapper"></div>
      </div>
    </div>
  );
}
