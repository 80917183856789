import React, { useState } from 'react';

function CreditCard(props) {
    let { cardDetails, limitDetails, cvvDetails, balanceAmount } = props;
    const [cardVisible, setCardVisible] = useState(false);
    const [cvvVisible, setCvvVisible] = useState(false);

    const setValueCardVisible = () => {
        setCardVisible(!cardVisible)
    }

    const handleVisibility = () => {
        setCvvVisible(!cvvVisible);
    }

    // Check if props are undefined and return a loader or a placeholder
    if (!cardDetails || !limitDetails || !cvvDetails) {
        // return <div>Loading...</div>;
    }

    const formatCardNumber = (num) => {
        // Convert the number to a string
        const str = num.toString();

        // Use a regular expression to insert a space every 4 characters
        return str.replace(/\d{4}(?=.)/g, '$& ');
    };

    return (
        <div className="credit-card-wrapper">
            <div className="credit-card-container">
                <img className="card-balloon-icon" src={require('../../../../src/assets/img/card-balloon.png')} alt='' />
                <img className="mastercard-icon" src={require('../../../../src/assets/img/mastercard-logo.png')} alt='' />
                <div className="credit-card-top-wrapper">
                    <div className="balance-wrapper">
                        <div className="balance-text">Current Balance</div>
                        <div className="balance-value">
                            {limitDetails && (
                                <>
                                    <i className="fa-solid fa-indian-rupee-sign rupee-icon"></i> {limitDetails.availableLimit}
                                </>)}
                        </div>
                    </div>
                </div>
                <div className="credit-card-name-wrapper">
                    <div className="credit-card-name-container">
                        {cardDetails?.customer?.name}
                    </div>
                </div>
                {cardDetails?.card?.cardNo && (
                    <div className="credit-card-bottom-wrapper">
                        <div className="credit-card-bottom-container">
                            <div className="credit-card-number-wrapper">
                                <span className="card-number-text">{cvvVisible === true ? ('**** **** **** ' + cardDetails?.card?.cardNo?.substring(12, 16)) : formatCardNumber(cardDetails?.card?.cardNo)}</span>
                            </div>

                            <div className="credit-card-details-wrapper">
                                <div className="card-details-item">
                                    <div className="card-details-label">Exp:</div>
                                    <div className="card-details-value">{cardDetails ? cardDetails?.card?.expiryDate.substring(0, 2) : "loading"}/{cardDetails ? cardDetails.card.expiryDate.substring(2, 4) : "loading"}</div>
                                </div>
                                <div className="card-details-item">
                                    <div className="card-details-label">CVV:</div>
                                    <div className="card-details-value">{cvvVisible === true ? cvvDetails ? cvvDetails.cvv : "loading" : '***'}</div>

                                    <div className="card-details-value">
                                        <i className={cvvVisible === true ? "feather-eye-off" : "feather-eye"} onClick={handleVisibility}></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CreditCard;
