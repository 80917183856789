import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

export function StatementDetailsTransactions(props) {
    const { transactions = [] } = props;

    const navigate = useNavigate();

    const handleEmiTransaction = () => {
        navigate('/easy-emi')
    }
    return (
        <div className="transactions-wrapper page-wrapper">
            <div className="transactions-header">
                <h4 className="transactions-title">Transactions</h4>
                <div className="transactions-header-actions ms-auto">
                    {/* <button className="btn"><i className="feather-chevron-right"></i></button> */}
                </div>
            </div>
            <div className="transactions-body">
                {transactions?.map((details, index) => (
                    <div className="transactions-item-wrapper" onClick={handleEmiTransaction}>
                        <div className="credited-card transactions-item-container">
                            <div className="item-icon-wrapper">
                                <div className={details?.transactionType === 'DEBIT' ? "item-icon-container-debit" : "item-icon-container-credit"}>
                                    <i className={details?.transactionType === 'DEBIT' ? 'fa-minus fa-solid' : 'fa-plus fa-solid'}></i>
                                </div>
                            </div>
                            <div className="item-text-wrapper">
                                <div className="item-name">{details?.description}</div>
                                <div className="item-time">{moment(details?.transactionDate).format('MMMM Do YYYY, h:mm:ss a')}</div>
                            </div>
                            <div className="item-value-wrapper">
                                <div className="item-total-value">
                                    <i className="fa-solid fa-indian-rupee-sign"></i>{details?.amount} <i className='fas fa-angle-right'></i>
                                </div>
                                {/* <div className="item-value">
                                    <span className="indicator">+</span><i className="fa-solid fa-indian-rupee-sign"></i>47.25
                                </div> */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="transactions-body">
                <div className="transactions-item-wrapper" onClick={handleEmiTransaction}>
                    <div className="credited-card transactions-item-container">
                        <div className="item-icon-wrapper">
                            <div className="item-icon-container">
                                <i className="fa-plus fa-solid"></i>
                            </div>
                        </div>
                        <div className="item-text-wrapper">
                            <div className="item-name">UPI/P2M/4578965487</div>
                            <div className="item-time">Today . 10:21 AM</div>
                        </div>
                        <div className="item-value-wrapper">
                            <div className="item-total-value">
                                <i className="fa-solid fa-indian-rupee-sign"></i>81547.25 <i className='fas fa-angle-right'></i>
                            </div>
                            <div className="item-value">
                                <span className="indicator">+</span><i className="fa-solid fa-indian-rupee-sign"></i>47.25
                            </div>
                        </div>
                    </div>
                </div>
                <div className="transactions-item-wrapper" onClick={handleEmiTransaction}>
                    <div className="pending-card transactions-item-container">
                        <div className="item-icon-wrapper">
                            <div className="item-icon-container">
                                <i className="fa-clock fa-regular"></i>
                            </div>
                        </div>
                        <div className="item-text-wrapper">
                            <div className="item-name">UPI/P2M/4578965235</div>
                            <div className="item-time">Yesterday . 3:32 PM</div>
                        </div>
                        <div className="item-value-wrapper">
                            <div className="item-total-value">
                                <i className="fa-solid fa-indian-rupee-sign"></i>81578.25 <i className='fas fa-angle-right'></i>
                            </div>
                            <div className="item-value">pending
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
