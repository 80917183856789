import { useState, useEffect } from 'react';
import { fetchCardBalance, fetchCardDetails, fetchCvvDetails, fetchLimitDetails, fetchUnbilledTransactions } from '../../common/cms-sdk/index';

export function HomeScreenApis() {
    let [cardBalance, setCardBalance] = useState(0);
    let [cardDetails, setCardDetails] = useState();
    let [cvvDetails, setCvvDetails] = useState();
    let [limitDetails, setLimitDetails] = useState();
    let [unbilledTransactions, setUnbilledTransactions] = useState();
    let [cardLoading, setCardLoading] = useState(true);
    let [cardApisCalls, setCardApisCalls] = useState(false);

    async function cardDetailsApicall() {
        setCardLoading(true);

        const BalanceDetails = await fetchCardBalance();
        if (BalanceDetails) {
            setCardBalance(BalanceDetails?.balanceAmount);
        }

        const detailsOfCard = await fetchCardDetails();
        setCardDetails(detailsOfCard);
        if(detailsOfCard?.card?.kitNo && detailsOfCard?.card?.expiryDate && detailsOfCard?.customer?.dob) {
            const cvv = await fetchCvvDetails({
                kitNo: detailsOfCard?.card?.kitNo,
                expiryDate: detailsOfCard?.card?.expiryDate,
                dob: detailsOfCard?.customer?.dob
            });
            setCvvDetails(cvv);
        }

        const limit = await fetchLimitDetails();
        setLimitDetails(limit);

        const details = await fetchUnbilledTransactions();
        setUnbilledTransactions(details);

        setCardLoading(false);
    }

    useEffect(() => {
        cardDetailsApicall();
        setCardApisCalls(false);
    }, [cardApisCalls]);

    return { cardApisCalls, setCardApisCalls, cardBalance, cardDetails, cvvDetails, limitDetails, unbilledTransactions, cardLoading };
}
