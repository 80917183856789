import React, { useState, useEffect, useCallback } from "react";
import { tokenGenerateCall, unlockCard, cardLock, cardClose, cardBlock, cardReplace } from '../cms-sdk/index';
import { EnterOtp } from '../../common/enter-otp/enter-otp';
import { Endpoints } from '../../common/cms-sdk/api';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const MpinPopup = ({ modalTitle, modalDescription, onSubmit, submitText, closeModal, showToast, apiDetails, actionReason }) => {

  const [mpinData, setMpinData] = useState();
  const [validMpin, setValidMpin] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const setMpinValue = (data) => {
    setMpinData(data);
    if (data?.value?.length === 4) {
      setValidMpin(true);
      if (data?.isSubmit) {
        setLoading(true);
        handleTokenGenerate(data?.value);
      }
    } else {
      setValidMpin(false);
    }
  };

  const handleOtpConfirmClick = () => {
    if (mpinData?.value?.length === 4) {
      setLoading(true);
      handleTokenGenerate(mpinData?.value);
    }
  }

  const handleTokenGenerate = useCallback(async (mpinValue) => {
    let action = '';
    let reason = '';
    if (apiDetails === Endpoints.LOCK_CARD) {
      action = 'card_lock';
      reason = 'Lock Card';
    } else if (apiDetails === Endpoints.UNLOCK_CARD) {
      action = 'card_unlock';
      reason = 'Unlock Card';
    } else if (apiDetails === Endpoints.BLOCK_CARD) {
      action = 'card_block';
      reason = 'Block Card';
    } else if (apiDetails === Endpoints.CLOSE_CARD) {
      action = 'card_close';
      reason = 'Close Card';
    } else if (apiDetails === Endpoints.REPLACE_CARD) {
      action = 'card_replace';
      reason = 'Replace Card';
    }
    if (actionReason) {
      reason = actionReason;
    }
    const tokenGenerateResponse = await tokenGenerateCall(mpinValue, action, showToast);
    if (tokenGenerateResponse) {
      cardActions('', reason);
    } else {
      setLoading(false);
    }
  })

  const cardActions = useCallback(async (kitNo, reason) => {
    console.log('reason: ', reason);
    let cardActionResponse = '';
    let message = '';
    if (apiDetails === Endpoints.LOCK_CARD) {
      cardActionResponse = await cardLock(kitNo, reason);
      message = 'Failed to lock the card. Please try again later';
    } else if (apiDetails === Endpoints.UNLOCK_CARD) {
      cardActionResponse = await unlockCard(kitNo, reason);
      message = 'Failed to unlock the card. Please try again later';
    } else if (apiDetails === Endpoints.BLOCK_CARD) {
      cardActionResponse = await cardBlock(kitNo, reason);
      message = 'Failed to unlock the card. Please try again later';
    } else if (apiDetails === Endpoints.CLOSE_CARD) {
      cardActionResponse = await cardClose(kitNo, reason);
      message = 'Failed to unlock the card. Please try again later';
    } else if (apiDetails === Endpoints.REPLACE_CARD) {
      cardActionResponse = await cardReplace(kitNo, reason);
      message = 'Failed to unlock the card. Please try again later';
    }
    console.log('cardActionResponse: ', cardActionResponse);
    if (cardActionResponse?.status === true) {
      // resultData = resultData?.data;
      if (cardActionResponse?.message) {
        showToast(cardActionResponse.message, 'success');
      }
      onSubmit();
      setLoading(false);
    } else {
      showToast(message, 'danger');
      setLoading(false);
    }
  })

  return (
    <>
      <div className="modal-backdrop" onClick={!isLoading ? closeModal : null}></div>
      <div className="modal bottom-custom-modal open-modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title has-close-btn">{modalTitle}</h4>
              <button type="button" aria-label="Close" className="btn modal-close" onClick={closeModal} disabled={isLoading}>
                <i className="feather-x-circle"></i>
              </button>
            </div>
            <div className="modal-body mpin-popup-body">
              <EnterOtp label={'Enter M-PIN'} otpLength={4} controlType={'password'} setValue={setMpinValue} />
              <p className='text-center'>{modalDescription}</p>
            </div>
            <div className='modal-footer'>
              <button className="btn btn-primary btn-lg btn-circular" onClick={handleOtpConfirmClick} disabled={!validMpin || isLoading}>{submitText}</button>
            </div>
          </div>
        </div>
      </div>
      {/* {isLoading && (
        <div className="custom-loader">
          <div className="custom-loader-icon">
            <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7' }} />
          </div>
        </div>
      )} */}
    </>
  );
};
export default MpinPopup;