import React from 'react';

export function StatementDetailsMenu(props) {
    const { statementData = [] } = props;
    return (
        <>
            <div className="bill-status-section sticky-top">
                <p className="bill-status"><span className="green-text"><i className="fas fa-check-circle me-2"></i> Bill paid fully on 6th Mar 2024</span></p>
            </div>

            <div className="payment-details-section">
                <div className="payment-total-due">
                    <h4>Total due</h4>
                    <p className="amount-total-due-amount">&#8377;{statementData?.totalCreditAmount}</p>
                </div>
                <div className="payment-minimum-due">
                    <h4>Minimum due</h4>
                    <p className="amount-minimum-due-amount">&#8377;{statementData?.minimumDueAmount}</p>
                </div>
                <div className="payment-amount-paid">
                    <h4>Amount paid</h4>
                    <p className="amount-paid-amount green-text">+ &#8377;{statementData?.totalDebitAmount}</p>
                </div>
            </div>
        </>
    );
}