import React from 'react';
import { PreviousStatementsApis } from '../common/previous-statement-apis.js';

import { StatementHeader } from './components/statement-header.js';
import { StatementList } from './components/statement-list.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export function StatementsIndex() {
    const { previousStatemenLoading, previousStatements } = PreviousStatementsApis();
    return (
        <>
            <StatementHeader />
            {previousStatemenLoading ? (
                <div className="loader">
                    <FontAwesomeIcon icon={faSpinner} spin style={{ fontSize: '3rem', color: '#8333D7', position: 'absolute', left: '45%', top: '40%' }} />
                </div>
            ) : (
                <div className="body-wrapper has-sticky-header">
                    <div className="secondary-header-wrapper">
                        <div>View or download all your statements here</div>
                    </div>
                    <div className="body-container">
                        <StatementList previousStatements={previousStatements} />
                    </div>
                </div>
            )}
        </>
    )
}
